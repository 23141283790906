<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="常见问题" />
    <div class="list">
      <div class="item" :class="item.show ? 'active' : ''" v-for="(item, index) in questionList" :key="index" @click="item.show = !item.show">
        <div class="title-item">
          <div class="icon q">Q</div>
          <div class="title">{{ item.CnTitle }}</div>
          <div class="more img-cover"><img src="../../assets/images/arrow-down-icon.png" /></div>
        </div>
        <div class="detail">
          <div class="icon a">A</div>
          <div class="content" v-html="item.CnContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "Question",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      questionList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted () {
    this.handleGetQuestion()
  },
  methods: {
    async handleGetQuestion() {
      var res = await this.$API.questionList.post()
      for (let index = 0; index < res.length; index++) {
        res[index].show = false
      }
      this.questionList = res
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
}
.list {
  padding: .24rem .32rem;
  .item {
    margin-bottom: .2rem;
    background-color: #fff;
    border-radius: .16rem;
    padding: .24rem;
    cursor: pointer;
    .title-item {
      display: flex;
      justify-content: space-between;
      .title {
        flex: 1;
        line-height: .4rem;
        padding: 0 .16rem;
      }
      .more {
        margin-top: .1rem;
        width: .2rem;
        height: .2rem;
      }
    }
    .icon {
      margin-top: .02rem;
      width: .36rem;
      height: .36rem;
      border-radius: .06rem;
      background-color: #1399ff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: .24rem;
      font-weight: bold;
    }
    &.active {
      .detail {
        display: flex;
      }
      .more {
        transform: rotate(180deg);
      }
    }
    .detail {
      display: none;
      margin-top: .26rem;
      justify-content: space-between;
      .icon {
        background-color: #c8cacc;
      }
      .content {
        flex: 1;
        line-height: .4rem;
        padding: 0 .16rem;
      }
    }
  }
}
//        
</style>